import React from 'react';
import RootLayout from './src/layouts/RootLayout';

export const onInitialClientRender = () => {
  console.log(`© ${new Date().getFullYear()} Grand Rapids Tech`);
}

export const wrapRootElement = ({ element }) => (
  <RootLayout>
    {element}
  </RootLayout>
);
