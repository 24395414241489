import React from 'react'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet'

const RootLayout = ({ children }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      />
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        {children}
      </IconContext.Provider>
    </>
  )
}

export default RootLayout
